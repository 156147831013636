import { storeActions } from '@/store/store-types';

export const ViewUtilitiesMixin = {
  methods: {
    async storeDispatchWithNotification({ dispatchAction, data, infoMessage, successMessage, errorMessage }) {
      if (!dispatchAction) {
        throw new Error('storeDispatchWithNotification - dispatchAction cannot be null');
      }

      if (!errorMessage) {
        throw new Error('storeDispatchWithNotification - errorMessage cannot be null');
      }

      try {
        await this.$store.dispatch(dispatchAction, data);

        if (successMessage) {
          this.$store.dispatch(storeActions.notifications.success, successMessage);
        }

        if (infoMessage) {
          this.$store.dispatch(storeActions.notifications.info, infoMessage);
        }
      } catch (err) {
        this.$log.error('storeDispatchWithNotification error: ', err.message || err);
        this.$store.dispatch(storeActions.notifications.error, errorMessage);
      }
    }
  }
};
