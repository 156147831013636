import { notificationsModuleActions } from './modules/notifications/store-notifications-types';
import { settingsModuleActions } from './modules/settings/store-settings-types';
import { streamSourcesModuleActions } from './modules/stream-sources/store-stream-sources-types';

const notificationsNamespace = 'notifications/';
const settingsNamespace = 'settings/';
const streamSourcesNamespace = 'streamSources/';
export const storeActions = {
  notifications: {
    success: notificationsNamespace + notificationsModuleActions.success,
    error: notificationsNamespace + notificationsModuleActions.error,
    info: notificationsNamespace + notificationsModuleActions.info,
    warning: notificationsNamespace + notificationsModuleActions.warning,
    audit: notificationsNamespace + notificationsModuleActions.audit
  },
  settings: {
    create: settingsNamespace + settingsModuleActions.create,
    delete: settingsNamespace + settingsModuleActions.delete,
    update: settingsNamespace + settingsModuleActions.update,
    list: settingsNamespace + settingsModuleActions.list
  },
  streamSources: {
    create: streamSourcesNamespace + streamSourcesModuleActions.create,
    delete: streamSourcesNamespace + streamSourcesModuleActions.delete,
    update: streamSourcesNamespace + streamSourcesModuleActions.update,
    list: streamSourcesNamespace + streamSourcesModuleActions.list
  }
};
